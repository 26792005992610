<template>
  <v-container fluid
    v-if="openModal"
  >
    <v-row justify="center">
      <v-dialog
        v-model="openModal"
        max-width="400"
        persistent
      >
        <v-card class="px-7 pt-7 pb-5">
          <v-card-text class="pa-0 pb-5">
            <p>
              <slot></slot>
            </p>
          </v-card-text>
          <v-card-actions class="pa-0 pt-3 justify-end">
            <v-btn
              :disabled="loading"
              depressed
              color="primary"
              @click="confirmed"
            >
              削除する
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="close"
            >
              閉じる
            </v-btn>
          </v-card-actions>

          <!-- ローダー -->
          <v-progress-linear
            :active="loading"
            :indeterminate="loading"
            absolute
            top
            color="primary"
          ></v-progress-linear>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
export default {
  components: {},

  data() {
    return {
      comeBack: {},
      submitCallback: null,
      openModal: false,
      loading: false,
    }
  },

  methods: {
    open(modalHanddown) {
      this.openModal = true
      this.comeBack = modalHanddown.comeBack
      this.submitCallback = modalHanddown.submitCallback
    },

    close() {
      this.loading = false
      this.openModal = false
      this.submitCallback = null
    },

    confirmed() {
      this.loading = true

      if (this.submitCallback) {
        this.submitCallback(this.comeBack)
      }
      this.$emit("modal-confirmed");
    }
  }
}
</script>

<style scoped>
</style>
