<template>
  <v-app-bar app
    dense elevate-on-scroll
    color="primary"
  >
    <h5>
      <slot name="content-info"></slot>
    </h5>

    <div class="ml-auto">
      <v-tooltip bottom
        v-for="(btn, index) in btns"
        :key="index + '-' + btn.icon"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :class="{'ml-2': btns.length > 1}"
            small
            elevation="0"
            color="accent"
            @click="$emit(btn.event)"
            v-bind="attrs"
            v-on="on"
          >
            {{ btn.label }}<v-icon small>{{ mdiIcon(btn.icon) }}</v-icon>
          </v-btn>
        </template>
        <span>{{ btn.tip }}</span>
      </v-tooltip>
    </div>
  </v-app-bar>
</template>

<script>

export default {
  components: {
  },

  props: {
    btns: {
      type: Array,
      required: false,
    },
  },

  data() {
    return {
    };
  },

  computed: {
    mdiIcon() {
      return name => 'mdi-' + name
    }
  },

  methods: {
    // onScroll(e) {
    //   this.passedThreshold = e.currentTarget.scrollY >= 48
    // }
  }
};
</script>

<style scoped>
.scrolled {
  position: fixed;
  top: 0;
  width: calc(100% - var(--nav-drawer-width));
  z-index: 5;
}
</style>
